import { createGlobalState } from "@vueuse/core";
import { db } from "@/services/firebase/Instance";
import userDatabase from "~/services/database/user";

export default createGlobalState(() => { 
  return {
    setNewUser,
    insertUser,
    getUserByPhone,   
    getUserByLineId,
    updateUserEvent,
    getUsersByEventId,
    deleteUser
  };

  async function insertUser(payload:any):Promise<any> {
    return userDatabase()._create(payload)
  }

  async function setNewUser(userId:string, payload:any):Promise<any> {
    return userDatabase()._set(userId, payload)
  }

  async function getUserByPhone(phoneNumber:string, callback:(user:[])=>void):Promise<any> {
    return userDatabase()._getWhere("phoneNumber", phoneNumber, callback)
  }

  async function getUserByLineId(lineId:string):Promise<any> {
    return userDatabase()._get(lineId)
  }

  async function updateUserEvent(userId:string, payload:any):Promise<any> {
    return userDatabase()._setAndMerge(userId, payload)
  }

  async function getUsersByEventId(eventId:string, callback:(user:[])=>void):Promise<any> {
    return userDatabase()._getWhere("eventId", eventId, callback)
  }

  async function deleteUser(userId: string) {
    return await userDatabase()._delete(userId)
  }
});
