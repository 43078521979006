import { createGlobalState } from "@vueuse/core";
import { reactive, ref as refVue } from "vue";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
  orderBy,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "@/services/firebase/Instance";
import _ from "lodash";

export default  createGlobalState(() => {

  return {
    _realtimeFetch,
    _realtimeFetchSortedWishes
  };

  function _realtimeFetch (eventId: string, callback: (event: Event) => void): Unsubscribe {   
    const q = query(
      collection(db,`events/${eventId}/wishes`)      
    );

    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {     
      let wishes = []
      querySnapshot.forEach((doc) => { 
        const data = doc.data() 
        if(!data.version || data.version !== 'v2' ) {
          wishes.push({ id: doc.id, ...doc.data() })    
        }
      }); 
      callback(wishes)
    });
    return unsubscribe
  }

  function _realtimeFetchSortedWishes(eventId: string, callback: (event: Event) => void) {

    const q = query(
      collection(db,`events/${eventId}/sorted`)      
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {     
      let wishObj:any = {}
      querySnapshot.forEach((doc) => { 
        wishObj = doc.data() 
      }); 
      callback(wishObj)
    });
    return unsubscribe

  }
});
