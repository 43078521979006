export default {
  standard: {
    standard01: true,
    standard02: true,
    standard03: true,
    standard04: true,
    standard05: true,
    standard06: true,
    standard07: true,
    standard08: true,
    standard09: true,
    standard10: true,
    standard11: true,
    standard12: true,
    standard13: true,
    standard14: true,
    standard15: true,
    standard16: true,
    standard17: true,
    standard18: true,
    standard19: true,
    standard20: true,
    standard21: true,
    standard22: true,
    standard23: true,
    standard25: true,
    standard26: true,
    standard27: true,
  },
  remove: {
    remove01: true,
    remove02: true,
    remove03: true,
    remove04: true,
    remove05: true,
    remove06: true,
    remove07: true,
    remove08: true,
    remove09: true,
  },
  premium: {},
};
