import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, updateDoc, onSnapshot, Unsubscribe, deleteField , query, collection, getDocs, where} from 'firebase/firestore'
import { Event } from '~/interfaces/database/event'

const eventDatabase = () => {
  const collectionName = 'events'

  const get = async (eventId: string): Promise<Event | null> => {
    const docRef = doc(db, collectionName, eventId)
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()) {
      return Object.assign({ docId: docSnap.id }, docSnap.data()) as Event
    } else {
      return null
    }
  }

  const getEventByPairCode = async (pairCode: string): Promise<Event | null> => {
    const q = query(collection(db, collectionName), where('pairCode', '==', pairCode));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0]; // Assuming pairCode is unique and there is only one match
        return Object.assign({ docId: docSnap.id }, docSnap.data()) as Event;
    } else {
        return null;
    }
  };

  const _create = async (eventId: string, payload: Event) => {
    const docRef = doc(db, collectionName, eventId)
    return await setDoc(docRef, payload)
  }
  const _update = async (eventId: string, payload: Event) => {
    const docRef = doc(db, collectionName, eventId)   
    return updateDoc(docRef, payload as any)
  }
  

  const _updateOrRemoveField = async (eventId: string, payload: any) => {
    const docRef = doc(db, collectionName, eventId);
  
    // Flatten the payload to handle deleteField correctly
    const updateData: any = {};
    for (const key in payload) {
      if (typeof payload[key] === 'object' && payload[key] !== null) {
        for (const nestedKey in payload[key]) {
          if (payload[key][nestedKey] === deleteField()) {
            updateData[`${key}.${nestedKey}`] = deleteField();
          } else {
            updateData[`${key}.${nestedKey}`] = payload[key][nestedKey];
          }
        }
      } else {
        updateData[key] = payload[key];
      }
    }
  
    return updateDoc(docRef, updateData);
  };

  const _set = async (eventId: string, payload: Event) => {
    const docRef = doc(db, collectionName, eventId)
    return setDoc(docRef, payload as any, { merge: true })
  }
  const _realtimeFetch = (eventId: string, callback: (event: Event) => void): Unsubscribe => {
    const unsub = onSnapshot(doc(db, collectionName, eventId), (doc) => {
      const data = doc.data()

      if(!data) {
        callback(null)
        return
      }

      const eventData: any = { docId: doc.id, ...doc.data() }
      callback(eventData)
    })

    return unsub
  }

  return {
    get,
    _create,
    _update,
    _updateOrRemoveField,
    _set,
    _realtimeFetch,
    getEventByPairCode
  }
}

export default eventDatabase