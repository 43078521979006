import { navigateTo } from "nuxt/app";
import useAuth from "@/composables/useAuth";
import { useUserStore } from "@/stores/user";
import { useEventStore } from "@/stores/event";
import { useAppStateStore } from "@/stores/appState";
import { useWishStore } from "@/stores/wish";
import { usePromptpayStore } from "@/stores/promptpay";
import useLine from "@/composables/useLine"
import useDefaultField from "@/composables/useDefaultField"
import {useEvent} from "@/composables/useEvent"

export const useAuthStore = defineStore("auth", {
  state: () => ({    
    uid: null,
    authUser: null,
    user: null,
    role: "user",
    unsubScribe: null,
    line: null,
    isDebugMode: false,
  }),
  actions: {
    async main() {
      return new Promise(async (resolve, reject) => {           
        const userStore = useUserStore()
        const eventStore = useEventStore()
        const appStateStore = useAppStateStore()
        const wishStore = useWishStore()
        const promptpayStore = usePromptpayStore()
        const liff = await useLine().init()   

        if (this.isDebugMode) {
          this.runDevMode(resolve)
          return
        }

        if (liff.isInClient()) {     
          
          this.line = await liff.getProfile()
          let user = await userStore.getUserById(this.line.userId);

          console.log(user)
          
          const invite_eventId = localStorage.getItem("invite_eventId");
          // checkout if invite with eventId
          if(invite_eventId) {
            const invite_mode = localStorage.getItem("invite_mode") || 'viewer';
            const inviteUser = await userStore.setInviteUser(invite_mode)
            this.setUserAuthData(inviteUser);
          } else if (!user) {
            navigateTo("/auth/validate");
            return;           
          }else{
            this.setUserAuthData(user);
          }
          
          resolve(this.line);
        } else {
          if (liff.isLoggedIn()) {
            const lineProfile = await liff.getProfile()
            this.line = lineProfile         

            let user = await userStore.getUserById(this.line.userId);    
            
            const invite_eventId = localStorage.getItem("invite_eventId");
            
            // checkout if invite with eventId
            if(invite_eventId) {
              const invite_mode = localStorage.getItem("invite_mode") || 'viewer';
              const inviteUser = await userStore.setInviteUser(invite_mode)
              this.setUserAuthData(inviteUser);
            } else if(!user){
              navigateTo("/auth/validate");
              return; 
            }else{
              this.setUserAuthData(user);              
            }
            resolve(this.line);

          } else {
            reject("Please login");
          }
        }

      });
    },

    // async registerNewUser(){
    //   const userStore = useUserStore()
    //   const eventStore = useEventStore()
    //   const appStateStore = useAppStateStore()

    //   const eventIdConverted = await useEvent().convertUserIdToEventId(this.line.userId ,6)
    //   const newEventId = eventIdConverted.toUpperCase()
    //   const userPayload = {...useDefaultField().user.register(lineProfile ,newEventId )}  
    //   const eventPayload = {...useDefaultField().event.register(userPayload ,newEventId )}
                 

    //   await eventStore.createEvent(newEventId, eventPayload);
    //   await userStore.setNewUser(this.line.userId, userPayload);
    //   this.setUserAuthData(userPayload);  
    // },
    async runDevMode(resolve){
      const userStore = useUserStore()
      const appStateStore = useAppStateStore()

      let profile = {
        // userId: "U5a0628604e11998d1b6f22a3856e7197",   //keng
        // userId:  "U03170be58f92cd95742cd4fcd729043a",  //nat
        // userId :  'U31755c7e54ce12ea295e33561895b2ec', //rice
        userId : 'U79cb50d879d85d2641e2ca77ff9c5816', //chompoo
        // userId : 'U88ba704e3db3c06d77b20d6bf857a22a', //chompoo2
        // userId : 'testuser2222', //chompoo2
        displayName: "MY_USER",
        pictureUrl: "/images/user.png",
      };
      this.line = profile;
      let user = await userStore.getUserById(profile.userId);

      if (!user) {
        navigateTo("/auth/validate");
        // appStateStore.setShowMainModal(true)
        return;
      }

      this.setUserAuthData(user);
      resolve(this.line);
    },
    async fetchAuth() {
      const user = await useAuth().currentUser();
      const userStore = useUserStore()

      if (user) {
        const { displayName, email, phoneNumber, photoURL, uid } = user;
        this.uid = uid;
        this.authUser = {};
        this.authUser.displayName = displayName;
        this.authUser.email = email;
        this.authUser.phoneNumber = phoneNumber;
        this.authUser.photoURL = photoURL;
        this.authUser.uid = uid;

        this.role = adminList.find(
          (item) => item.email === email || item.phoneNumber === phoneNumber || item?.userId === uid
        )
          ? "admin"
          : "user";

        const { _user, _unsubScribe } = await userStore.getUserByPhone(phoneNumber);
        this.user = _user.length > 0 ? _user[0] : null;  
        
        if(_user?.admin) {
          this.role = 'admin';
        }

        this.unsubScribe = _unsubScribe;
        if (!this.user) {
          return null;
        }
      }

      return user;
    },    
    setUserAuthData(user: any) {      
      this.uid = user.uid;
      this.authUser = {
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        uid: user.uid,
      };
      this.user = user;
      this.role = user?.admin === true ? 'admin' : 'user'
    },      
    async clearAuth() {
      await useAuth().signOutAuth();
      (this.uid = null),
        (this.authUser = null),
        (this.user = null),
        (this.role = "user");
      
    },  
    unSubscribeAuth() {
      if (!this.unsubScribe) return;
      this.unsubScribe();
    },    
  

  },
});

const adminList = [
  { email: "rice.th@gmail.com", phoneNumber: "+66818460621", userId: "U31755c7e54ce12ea295e33561895b2ec" },
  { email: "utp.naikorn@gmail.com", phoneNumber: "+66985461451", userId: "U79cb50d879d85d2641e2ca77ff9c5816"},
  { email: "warinsjoyjoy@gmail.com", phoneNumber: "+66981781918", userId: "Ufec11a39aa42386b2c211cf242a39e9e"},
  { email: "praearatchaporn@gmail.com", phoneNumber: "+66987654321", userId: "U225f13c0d19d902679c4b0a4d221ecf6"},
  { email: "the.harpforlen@gmail.com", phoneNumber: "+66938395558" , userId: "goffy"},
];


